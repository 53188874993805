// vendors
import React from "react"
import { css } from "@emotion/core"
import { fontSizes, fonts, colors } from "../../styles/variables"
import mediaQuery from "../../utils/media-query"

const ReviewCard = ({ quote, cite, review, ...props }) => (
  <div
    css={css`
      font-size: ${fontSizes[3] / fontSizes[0]}em;
      line-height: ${fontSizes[4] / fontSizes[3]};
      text-align: center;
      font-weight: bold;

      ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
        font-size: ${fontSizes[5] / fontSizes[1]}em;
      }
    `}
    {...props}
  >
    <div
      css={css`
        display: inline-flex;
        background-color: ${colors.pastelRed};
        font-size: ${fontSizes[4] / fontSizes[3]}em;
        justify-content: center;
        align-items: center;
        width: ${fontSizes[8] / fontSizes[4]}em;
        height: ${fontSizes[8] / fontSizes[4]}em;
        border-radius: 50%;
        margin: 0 auto ${fontSizes[4] / fontSizes[3]}em;

        ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
          font-size: 1em;
        }
      `}
    >
      {review}
    </div>
    <blockquote
      css={css`
        margin: 0;
      `}
    >
      <p>{quote}</p>

      <footer
        css={css`
          font-size: ${fontSizes[1] / fontSizes[3]}em;
          font-family: ${fonts.accent};

          ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
            font-size: ${fontSizes[3] / fontSizes[5]}em;
          }
        `}
      >
        <p
          css={css`
            :before {
              content: "— ";
            }
          `}
        >
          {cite}
        </p>
      </footer>
    </blockquote>
  </div>
)

export default ReviewCard
